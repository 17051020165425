.MyApplications-mainBox{
    padding: 32px 23%;
    .my-jobList{
        .my-jobCardBox{
            cursor: pointer;
            user-select: none;
            width: 880px;
            height: 143px;
            background: #FFFFFF;
            box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
            border-radius: 12px 12px 12px 12px;
            margin-bottom: 12px;
            padding: 16px;
            &:last-child{
                margin: 0;
            }
            &:first-child{
                margin-top: 20px;
            }
            .job-tit{
                font-family: Roboto-SemiBold;
                font-weight: 600;
                font-size: 22px;
                color: #1C253D;
                line-height: 26px;
            }
            .mini-tipBox-job{
                margin: 8px 0 16px 0;
                .jonInfo-icon{
                    width: 24px;
                    min-width: 24px;
                    height: 24px;
                    background: #F0F4F9;
                    border-radius: 4px 4px 4px 4px;
                    margin-right: 8px;
                }
                .job-info-tip{
                    font-weight: 400;
                    font-size: 12px;
                    color: #1C253D;
                    line-height: 14px;
                    text-align: left;
                }
                .round-spaceBox{
                    font-style: normal;
                    margin: 0 10px;
                }
            }
            .skill-tit{
                font-family: Roboto-Medium;
                font-weight: 500;
                font-size: 11px;
                color: #9490F1;
                line-height: 13px;
            }
            .skill-tip{
                font-weight: 400;
                font-size: 14px;
                color: #1C253D;
                line-height: 20px;
                margin-top: 4px;
            }
        }
    }
}