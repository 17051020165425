.portFolioDrawer-box{
    .ant-drawer-content{
        border-radius: 32px 32px 0 0 ;
    }
    .ant-drawer-content-wrapper{
        .ant-drawer-body{
            position: relative;
            padding: 70px 15%;
            .close-icon-drawer{
                position: fixed;
                z-index: 100;
                right: 20px;
                top: 4%;
                width: 24px;
                height: 24px;
                background: url('../../assets/img/Remove-drawer.png') no-repeat;
                background-size: contain;
                cursor: pointer;
                transition: all .3s;
            }
            .portFolio-tit{
                font-family: Roboto-SemiBold, Roboto;
                font-weight: 600;
                font-size: 22px;
                color: #1C253D;
                line-height: 26px;
                margin-left: 12px;
            }
            .marb24{
                margin-bottom: 24px;
            }
            .imgBox{
                width: 100%;
                // height: 588px;
                background-color: #ccc;
            }
            .textBox{
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                font-size: 24px;
                color: #1C253D;
                line-height: 32px;
                text-align: left;
            }
            .link-icon-draw{
                width: 22px;
                height: 22px;
                // background-color: #4C47DE;
                background: url('../../assets/img/Link@2x.png') no-repeat;
                background-size: contain;
                margin-right: 10px;
            }
            .footerLink{
                font-family: Roboto-SemiBold, Roboto;
                font-weight: 600;
                font-size: 16px;
                color: #4C47DE;
                line-height: 22px;
                text-align: left;
                text-decoration: underline;
            }
        }
    }
    .ant-drawer-mask{
        background-color: rgba(30, 34, 45, 0.8);
        backdrop-filter: blur(1px);
    }
}
.opeation-drawer{
    .ant-drawer-content-wrapper{
        .ant-drawer-body{
            padding: 32px 15% 20px 15%;
            .portFolio-titN{
                font-family: Roboto-SemiBold, Roboto;
                font-weight: 600;
                font-size: 27px;
                color: #1C253D;
                line-height: 34px;
                text-align: left;
            }
            .port-folioForm{
                .flex1{
                    flex:1;
                }
                .space20{
                    width: 20px;
                }
                .portItemCard-box{
                    .opeation-iconBox{
                        margin: 48px 0 10px 0;
                    }
                    &:first-child{
                        .opeation-iconBox{
                            margin: 28px 0 10px 0;
                        }
                    }
                    .draw-icon{
                        min-width: 32px;
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                        &:nth-child(2){
                            margin: 0 15px;
                        }
                        &:last-child{
                            margin-left: 15px;
                        }
                    }
                }
                .portItem-add{
                    margin-top: 48px;
                    width: 100%;
                    height: 152px;
                    background: #FFFFFF;
                    border-radius: 12px 12px 12px 12px;
                    border: 1px dashed rgba(28,37,61,0.5);
                    padding: 18px 0;
                    .add-box{
                        width: 115px;
                        height: 115px;
                        background: #F0F4F9;
                        border-radius: 12px 12px 12px 12px;
                        padding-top: 27px;
                        cursor: pointer;
                        &:nth-child(2){
                            margin: 0 10px;
                        }
                        .add-icon-draw{
                            display: block;
                            width: 36px;
                            height: 36px;
                            margin: 0 auto;
                            cursor: pointer;
                        }
                        .add-tip-draw{
                            font-weight: 400;
                            font-size: 11px;
                            color: #1C253D;
                            line-height: 13px;
                            text-align: center;
                            margin-top: 12px;
                            user-select: none;
                        }
                    }
                    position: relative;
                    #portfolioInput{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        opacity: 0;
                        z-index: -999;
                    }
                }
            }
        }
    }
}

.apply-drawer{
    .ant-drawer-content-wrapper{
        .ant-drawer-body{
            padding: 70px 15%;
            .apply-titN{
                font-family: Roboto-SemiBold, Roboto;
                font-weight: 600;
                font-size: 27px;
                color: #1C253D;
                line-height: 34px;
                text-align: left;
            }
            .apply-infoBox{
                margin-bottom: 28px;
                .comp-logo{
                    width: 52px;
                    height: 52px;
                    background: #F0F4F9;
                    border-radius: 12px 12px 12px 12px;
                    margin-right: 12px;
                }
                .comp-tit{
                    font-family: Roboto-SemiBold;
                    font-weight: 600;
                    font-size: 18px;
                    color: #1C253D;
                    line-height: 21px;
                }
                .comp-tip{
                    font-weight: 400;
                    font-size: 14px;
                    color: #1C253D;
                    line-height: 16px;
                    text-align: left;
                    margin-top: 5px;
                }
            }
            .apply-Form{
                .apply-cardBox{
                    width: 100%;
                    padding: 20px 16px;
                    background: #F0F4F9;
                    border-radius: 12px 12px 12px 12px;
                    margin-bottom: 12px;
                    .apply-formTip{
                        font-family: Roboto-Medium, Roboto;
                        font-weight: 500;
                        font-size: 16px;
                        color: #1C253D;
                        line-height: 22px;
                        text-align: left;
                        margin-bottom: 16px;
                    }
                    .seclectItem{
                        max-width: max-content;
                        user-select: none;
                        font-weight: 400;
                        font-size: 14px;
                        color: #1C253D;
                        line-height: 20px;
                        text-align: left;
                        margin-bottom: 10px;
                        cursor: pointer;
                        .selected_icon{
                            width: 16px;
                            min-width: 16px;
                            height: 16px;
                            margin-right: 10px;
                        }
                    }
                }
                .space20{
                    width: 20px;
                }
            }
        }
    }
}