.team-modalBox{
    .teamSubmit_btn{
        width: 185px;
        height: 48px;
        font-size: 16px;
        font-family: Roboto-Medium;
    }
    .down-right-icon{
        width: 12px;
        height: 12px;
        background: url('../../assets/img/arrow-down.png') no-repeat;
        background-size: contain;
        cursor: pointer;
    }
}



// video
.video-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  video {
    width: 100%;
    height: 451px;
    background-color: #191919;
    border-radius: 12px;
  }
  
  .custom-play-button {
    position: absolute;
    width: 94px;
    height: 94px;
    background: url('../../assets/img/play.png') no-repeat;
    background-size: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    // display: none; /* Initially hidden */
  }
  
  .video-container.paused .custom-play-button {
    display: block;
  }
  
// alert modal
.primary-modal-tip{
    font-weight: 400;
    font-size: 18px;
    color: #1C253D;
    line-height: 23px;
    text-align: center;
}
.sub-info-modal{
    .sub-boldTip{
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        font-size: 20px;
        color: #1C253D;
        line-height: 23px;
        text-align: left;
        margin: 8px 0 20px 0;
    }
    .noMar{
        margin: 0;
    }
}

.curModal-box{
    margin-top: 40px;
    .form-tip{
        margin-top: 20px;
    }
}