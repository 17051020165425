.sliderTabs-ul{
    width: max-content;
    height: 41px;
    background: #EAE9FC;
    border-radius: 12px;
    .tabs-itemTop{
        height: 100%;
        user-select: none;
        font-weight: 400;
        font-size: 14px;
        color: #1C253D;
        line-height: 17px;
        padding:0 16px;
        cursor: pointer;
        border-radius: 12px;
        display: flex;
        align-items: center;
        transition: all .2s;
        margin: 0;
        &.active{
            color: #fff;
            background: #9490F1;
            box-shadow: 0px 8px 12px 0px rgba(148,144,241,0.25);
        }
        &:last-child{
            margin: 0;
        }
    }
}