.r-pagination {
    margin-top: 30px;
    .ant-pagination-prev,
    .ant-pagination-next {
        min-width: 30px;
        height: 30px;
        // background: rgba(9, 125, 255, 0.1);
        // border-radius: 8px 8px 8px 8px;
        opacity: 1;
        .ant-pagination-item-link {
            color: #222222;
            display: flex;
            align-items: center;
            justify-content: center;
            &:disabled {
                // color: #909090;
                opacity: 0.3;
            }
        }
    }
    .ant-pagination-item {
        width: 30px;
        min-width: 30px;
        height: 30px;
        // background: #EDEDFC;
        border-radius: 6px;
        font-size: 14px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: #747474;
        margin-inline-end: 4px;
        border: none;
        a {
            padding: 0;
        }

        &.ant-pagination-item-active {
            background: #EDEDFC;
            a {
                color: #222222;
            }
        }
    }
    .opeationpage_btn {
        width: 44px;
        // height: 28px;
        // background: rgba(9, 125, 255, 0.1);
        // border-radius: 8px 8px 8px 8px;
        font-size: 14px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: #222;
        cursor: pointer;
        user-select: none;
        transition: all 0.1s;
    }
}
