.portfolioItem-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    .portfolioItem-card{
        width: calc((100% - 30px) / 4);
        margin-right: 10px;
        margin-bottom: 10px;
        &:nth-child(4n){
            margin-right: 0;
        }
        .portfolio-bg{
            width: 100%;
            height: 150px;
            border-radius: 12px 12px 12px 12px;
            background-color: #dcb7c5;
            cursor: pointer;
        }
        .port-name{
            width: 100%;
            margin-top: 10px;
            height: 30px;
            font-weight: 400;
            font-size: 12px;
            color: #1C253D;
            text-align: left;
        }
    }
}
.tabs-item{
    padding: 0 16px;
    height: 36px;
    background: #EAE9FC;
    border-radius: 8px 8px 8px 8px;
    font-weight: 400;
    font-size: 12px;
    color: #1C253D;
    line-height: 36px;
    user-select: none;
    margin-right: 8px;
    margin-bottom: 8px;
    .tabs-del{
        width: 12px;
        height: 12px;
        background: url('../../../assets/img/Remove-black.png') no-repeat;
        background-size: contain;
        cursor: pointer;
        margin-left: 8px;
    }
}