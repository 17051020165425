.userprofile-main{
    padding: 32px 0;
    width: 580px;
    margin: 0 auto;
    min-height: calc(100vh - 64px);
    background-color: #F0F4F9;
    .top-infoBox{
        padding: 12px;
        font-family: Roboto-SemiBold;
        font-weight: 600;
        font-size: 16px;
        color: #1C253D;
        line-height: 20px;
        .userProfile-tip{
            font-family: Roboto-Regular;
            font-weight: 400;
            font-size: 12px;
            color: #1C253D;
            line-height: 15px;
            text-align: left;
            margin-top: 5px;
        }
    }
    .viewMore-btn{
        width: 100%;
        user-select: none;
        cursor: pointer;
        height: 40px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #7F8AA9;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        font-size: 14px;
        color: #1C253D;
    }
    .userprofile-content{
        .pmarb20{
            margin-bottom: 20px;
        }
        .profile-card{
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
            border-radius: 12px 12px 12px 12px;
            padding: 16px;
            &.pmarl20{
                margin-left: 20px;
            }
            .user-img{
                width: 72px;
                height: 72px;
                border-radius: 8px;
            }
            .user-infoName{
                margin-left: 16px;
                font-family: Roboto-SemiBold;
                font-weight: 600;
                font-size: 22px;
                color: #1C253D;
                line-height: 28px;
                text-align: left;
            }
            .fz14{
                font-family: Roboto-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #1C253D;
                line-height: 18px;
                text-align: left;
            }
            .user-shareIcon{
                width: 32px;
                height: 32px;
                cursor: pointer;
                margin: 0 16px 0 auto;
            }
            .profile-iconInfo{
                padding: 4px 0 16px 0;
                border-bottom: 1px solid #DFDEFB;
                margin-bottom: 16px;
                .img-fbox{
                    margin-top: 12px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #1C253D;
                    line-height: 16px;
                    .userImg-box{
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
            }
            .p-urlTit{
                font-family: Roboto-SemiBold;
                font-weight: 600;
                font-size: 16px;
                color: #1C253D;
                line-height: 20px;
                margin-bottom: 5px;
            }
            .profile-urlBox{
                display: flex;
                align-items: flex-end;
                .p-url-link{
                    font-weight: 400;
                    font-size: 12px;
                    color: #4C47DE;
                    line-height: 15px;
                    text-decoration: underline;
                }
            }
            .profile-info-pri{
                font-weight: 400;
                font-size: 12px;
                color: #1C253D;
                line-height: 15px;
                text-align: left;
                font-family: Roboto-Regular;
            }
            .user-threeLinkImg{
                width: 32px;
                height: 32px;
                background: #F0F4F9;
                border-radius: 6px 6px 6px 6px;
            }
            .connected-box{
                width: 65px;
                height: 20px;
                background: #59C198;
                border-radius: 12px 12px 12px 12px;
                font-family: Roboto-SemiBold;
                font-weight: 600;
                font-size: 10px;
                color: #FFFFFF;
            }
            .relevantExperienceList{
                flex-wrap: wrap;
                .relevantExperience-item{
                    width: 176px;
                    height: 102px;
                    background: #F0F4F9;
                    border-radius: 8px 8px 8px 8px;
                    padding: 12px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                    &.greenBg{
                        background: #E2FAF1;
                    }
                    .experie-tit{
                        font-family: Roboto-SemiBold, Roboto;
                        font-weight: 600;
                        font-size: 18px;
                        color: #1C253D;
                        line-height: 25px;
                    }
                    .jobCheck-icon{
                        width: 16px;
                        height: 16px;
                        margin-left: 4px;
                    }
                    .experie-job{
                        font-size: 14px;
                        color: #1C253D;
                        font-family: Roboto-Regular;
                        margin: 12px 0 5px 0;
                    }
                    .experie-jobTime{
                        opacity: 0.5;
                        font-size: 12px;
                        color: #1C253D;
                        font-family: Roboto-Regular;
                    }
                }
            }
            .user-fileImg{
                width: 40px;
                height: 40px;
                margin-right: 12px;
            }
            .portList-box{
                flex-wrap: wrap;
                margin-top: 16px;
                .portImg-box{
                    cursor: pointer;
                    min-width: 176;
                    width: calc((100% - 20px) / 3);
                    height: 130px;
                    border-radius: 12px 12px 12px 12px;
                    background-color: #ccc;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    background-size: cover !important;
                    &:nth-child(3n){
                        margin-right: 0;
                    }
                }
            }
            .videoCard-box{
                &:nth-child(2){
                    margin: 0 10px;
                }
                flex: 1;
                .video-tip{
                    font-family: Roboto-Medium;
                    font-weight: 500;
                    font-size: 13px;
                    color: #1C253D;
                    line-height: 15px;
                    text-align: left;
                    margin-bottom: 12px;
                }
                .videoCard-bg{
                    width: 100%;
                    height: 205px;
                    background: rgba(28,37,61,0.7);
                    border-radius: 12px 12px 12px 12px;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        width: 42px;
                        height: 42px;
                        background: url('../../../assets/img/candidate-img/play-white.png') no-repeat;
                        background-size: contain;
                        cursor: pointer;
                    }
                }
            }
            .roleList-box{
                margin-bottom: 16px;
                &:last-child{
                    margin: 0;
                }
            }
            .roleImg{
                width: 32px;
                height: 32px;
                margin-right: 8px;
            }
            .msg-tit{
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                font-size: 14px;
                color: #1C253D;
                line-height: 16px;
                text-align: left;
            }
            .msg-tip{
                margin-top: 16px;
                font-weight: 400;
                font-size: 12px;
                color: #1C253D;
                line-height: 14px;
                text-align: left;
            }
        }
    }
    .user-table-box{
        margin-top: 18px;
        .team-table-th{
            width: 100%;
            height: 23px;
            border-bottom: 1px solid #DFDEFB;
            .thade-cell{
                padding: 5px 10px;
                flex: 1;
                font-family: Roboto-Medium;
                font-weight: 500;
                font-size: 11px;
                color: #9490F1;
                text-align: left;
            }
        }
        .team-table-body{
            padding-top: 12px;
            .team-table-tr{
                width: 100%;
                height: 52px;
                background: #FFFFFF;
                border-radius: 8px 8px 8px 8px;
                margin-bottom: 5px;
                .team-tb-cell{
                    font-family: Roboto-Regular;
                    flex: 1;
                    padding: 10px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #1C253D;
                    text-align: left;
                    &.job-cell{
                        color: #4C47DE;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
        .no-data{
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            font-size: 12px;
            color: rgba(28,37,61,0.5);
            line-height: 17px;
            text-align: center;
            margin-top: 22px;
        }
    }
}
// srawe form css
.calendar-card{
    width: 302px;
    height: 204px;
    background: #FFFFFF;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #DFDEFB;
    padding: 16px;
    &:last-child{
        margin-left: 20px;
    }
    .calendar-tit{
        font-family: Roboto-Medium;
        font-weight: 500;
        font-size: 24px;
        color: #1C253D;
        line-height: 30px;
        text-align: center;
        margin-bottom: 20px;
    }
}
.editInfo-tit{
    font-family: Roboto-Medium;
    font-weight: 500;
    font-size: 24px;
    color: #1C253D;
    line-height: 28px;
    text-align: left;
}
.editInfo-tip{
    font-weight: 400;
    font-size: 16px;
    color: #1C253D;
    line-height: 20px;
    text-align: left;
    margin: 10px 0 28px 0;
}

.info-tit{
    font-family: Roboto-SemiBold, Roboto;
    font-weight: 600;
    font-size: 27px;
    color: #1C253D;
    line-height: 34px;
    text-align: left;
}
.info-tip{
    font-weight: 400;
    font-size: 16px;
    color: #1C253D;
    line-height: 20px;
    text-align: left;
    margin: 8px 0 32px 0;
}
.pri-tit{
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    font-size: 18px;
    color: #4C47DE;
    line-height: 21px;
    text-align: left;
    margin-bottom: 20px;
}
.information-formBox{
    .skill-typeBox{
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        font-size: 16px;
        color: #1C253D;
        line-height: 20px;
        text-align: left;
        margin: 20px 0 16px 0;
    }
    .flex1{
        flex: 1;
    }
    .w640{
        width: 640px;
    }
    .video-card{
        width: calc((100% - 40px) / 3);
        &:nth-child(2){
            margin: 0 20px;
        }
        .video-tip{
            font-weight: 400;
            font-size: 11px;
            color: #1C253D;
            line-height: 14px;
            text-align: left;
            opacity: 0.7;
            margin: 20px 0 10px 0;
            min-height: 42px;
        }
        .remove-link{
            font-weight: 400;
            font-size: 12px;
            color: #F87171;
            line-height: 14px;
            text-align: left;
            text-decoration: underline;
            user-select: none;
            cursor: pointer;
            margin-top: 13px;
        }
    }
}
.info-line{
    width: 100%;
    height: 1px;
    background:  #DFDEFB;
    margin: 20px 0 ;
}
.experience-cardBox{
    width: 100%;
    // height: 414px;
    background: #F0F4F9;
    border-radius: 10px 10px 10px 10px;
    padding: 16px;
}
.cerinfo-card{
    width: 100%;
    min-height: 48px;
    background: #DFDEFB;
    border-radius: 8px 8px 8px 8px;
    padding: 12px;
    font-family: Roboto-Medium;
    font-size: 16px;
    margin-bottom: 10px;
    .exp-tip{
        color: #1C253D;
        opacity: 0.7;
        font-size: 11px;
        margin: 8px 0;
        font-family: Roboto-Regular;
    }
    .exp-size11{
        font-size: 11px;
        font-family: Roboto-Regular;
    }
    .exp-size12{
        font-size: 12px;
        margin-top: 8px;
        font-family: Roboto-Regular;
    }
}
.curModal-box-new{
    max-width: 830px;
    margin:  0 auto;
}