.home-mainBox{
    .top-alertBox{
        width: 100%;
        height: 36px;
        background: #59C198;
        padding:0 9%;
        .calendarSchedule-icon{
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-right: 8px;
        }
        .cal-msgBox{
            font-family: Roboto-Medium;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #FFFFFF;
            .msg-text{
                font-family: Roboto-Regular;
                margin-left: 8px;
            }
        }
    }
    .page-contentBox{
        padding: 32px 9%;
        .job-cardListBox{
            margin-left: 20px;
            height: 100%;
            .job-listBox{
                // height: calc(100vh - 240px);
                position: relative;
                overflow-y: auto;
                margin-top: 20px;
                .job-cardItem{
                    width: 100%;
                    min-height: 160px;
                    background: #FFFFFF;
                    // box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
                    border-radius: 12px 12px 12px 12px;
                    margin-bottom: 12px;
                    padding: 16px;
                    .job-infoLeft{
                        margin-right: 10px;
                        .job-tit{
                            font-family: Roboto-SemiBold;
                            font-weight: 600;
                            font-size: 22px;
                            color: #1C253D;
                            line-height: 26px;
                        }
                        .mini-tipBox-job{
                            margin: 8px 0 16px 0;
                            .jonInfo-icon{
                                width: 24px;
                                min-width: 24px;
                                height: 24px;
                                background: #F0F4F9;
                                border-radius: 4px 4px 4px 4px;
                                margin-right: 8px;
                            }
                            .job-info-tip{
                                font-weight: 400;
                                font-size: 12px;
                                color: #1C253D;
                                line-height: 14px;
                                text-align: left;
                            }
                            .round-spaceBox{
                                font-style: normal;
                                margin: 0 10px;
                            }
                        }
                        .skill-tit{
                            font-family: Roboto-Medium;
                            font-weight: 500;
                            font-size: 11px;
                            color: #9490F1;
                            line-height: 13px;
                        }
                        .skill-tip{
                            font-weight: 400;
                            font-size: 14px;
                            color: #1C253D;
                            line-height: 20px;
                            margin-top: 4px;
                        }
                    }
                    .job-opRight{
                        min-width: 150px;
                        width: 150px;
                        margin-left: 20px;
                        margin-left: auto;
                        .r-primary_btn{
                            width: 100%;
                            &:nth-child(2){
                                margin: 10px 0;
                            }
                        }
                    }
                }
                .loaderRef-box{
                    // background: pink;
                    position: absolute;
                    bottom: 20%;
                    width: 100%;
                    z-index: 100;
                }
            }
        }
        
        .seclectItem{
            max-width: max-content;
            user-select: none;
            font-weight: 400;
            font-size: 14px;
            color: #1C253D;
            line-height: 20px;
            text-align: left;
            margin-bottom: 8px;
            cursor: initial;
            .selected_icon{
                width: 16px;
                min-width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }
    }
}
@keyframes slide {
    0% {
        transform: translateY(100%); 
        opacity: 0.2;
    }
    10% {
        transform: translateY(0);
        opacity: 0.7;
    }
    20% {
        transform: translateY(0);
        opacity: 1;
    }
      80% {
        transform: translateY(0);
        opacity: 1;
    }
    90% {
        transform: translateY(0); 
        opacity: 0.7;
    }
    100% {
        transform: translateY(-100%); 
        opacity: 0;
    }
}
// share
.shareModal-box{
    margin-top: 30px;
    .share-cardBox{
        width: 100%;
        background: rgba(155,167,200,0.15);
        border-radius: 10px 10px 10px 10px;
        .topShare-iconBox{
            width: 448px;
            height: 120px;
            background: #1C253D;
            border-radius: 10px 10px 0px 0px;
            .TalenautLogoShare-img{
                height: 40px;
            }
        }
        .share-content{
            padding: 12px 20px 30px 20px;
            font-weight: 400;
            font-size: 13px;
            color: #1C253D;
            line-height: 20px;
            text-align: left;
            font-family: Roboto-Regular;
            .share-tit{
                font-family: Roboto-Bold;
                font-weight: bold;
                font-size: 20px;
                color: #1C253D;
                line-height: 23px;
                text-align: left;
            }
            .share-14{
                margin: 8px 0;
                font-weight: 400;
                font-size: 14px;
                color: #1C253D;
                line-height: 16px;
                font-family: Roboto-Regular;
            }
            .share-link{
                font-weight: 400;
                font-size: 12px;
                color: #4C47DE;
                line-height: 14px;
                text-align: left;
                font-style: normal;
                text-decoration-line: underline;
                margin-top: 12px;
            }
        }
    }
}
// job detail
.jobDetail-mainBox{
    .page-contentBox{
        .job-leftInfoBox{
            width: 280px;
            margin-right: 20px;
            .comp-icon{
                width: 94px;
                height: 94px;
                background: #F0F4F9;
                border-radius: 100px 100px 100px 100px;
            }
            .job-tit{
                font-family: Roboto-SemiBold;
                font-weight: 600;
                font-size: 22px;
                color: #1C253D;
                line-height: 26px;
                margin: 12px 0 8px 0;
            }
            .jobMin-tip{
                font-weight: 400;
                font-size: 12px;
                color: #1C253D;
                line-height: 14px;
            }
            .tipText-box{
                font-size: 14px;
                color: #1C253D;
                .job-leftIcon{
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }
            .jobRelevancy-tit{
                font-family: Roboto-Bold;
                font-size: 11px;
                line-height: 13px;
                font-weight: bold;
            }
            .chart-box{
                position: relative;
                .per-tip{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    font-family: Roboto-SemiBold;
                    font-weight: 600;
                    font-size: 28px;
                    color: #1C253D;
                }
            }
            #round-chart{
                width: 164px;
                height: 164px;
                margin: 25px auto;
            }
        }
        .job-rightInfoBox{
            flex: 1;
            .card-titBox{
                font-family: Roboto-Medium;
                font-weight: 500;
                font-size: 18px;
                color: #4C47DE;
                line-height: 21px;
                text-align: left;
                margin-bottom: 20px;
            }
            .card-tipBox{
                font-weight: 400;
                font-size: 14px;
                color: #1C253D;
                line-height: 20px;
                text-align: left;
                font-family: Roboto-Regular;
                &.showBe-box{
                    &::before{
                        content: '';
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: #1C253D;
                        margin:0 8px;
                    }
                }
            }
            .skill-tipImg{
                width: 16px;
                height: 16px;
                margin-left: 5px;
                cursor: pointer;
            }
        }
        .jobMsg-card{
            width: 100%;
            padding: 24px 16px;
            background: #FFFFFF;
            box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
            border-radius: 12px 12px 12px 12px;
            &.mart20{
                margin-top: 20px;
            }
        }
    }
}
.medium_btn{
    font-family: Roboto-Medium !important;
}
.close-job{
    flex: 1;
    height: 820px;
    background: #FFFFFF;
    box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
    border-radius: 12px 12px 12px 12px;
    .close-tit{
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #1C253D;
        line-height: 20px;
        text-align: center;
        font-style: normal;
        opacity: .5;
    }
}