.chat-mainBox{
    padding: 32px 9%;
    .chat-tit{
        font-family: Roboto-SemiBold;
        font-weight: 600;
        font-size: 27px;
        color: #1C253D;
        line-height: 32px;
        margin-bottom: 28px;
    }
    .chat-box{
        .chanel-list{
            width: 280px;
            min-width: 280px;
            max-height: 442px;
            // min-height: 240px;
            overflow-y: auto;
            background: #FFFFFF;
            box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
            border-radius: 12px 12px 12px 12px;
            padding: 16px;
            margin-right: 20px;
            .chanel-tit{
                font-family: Roboto-SemiBold;
                font-weight: 600;
                font-size: 22px;
                color: #1C253D;
                line-height: 26px;
                margin-bottom: 24px;
            }
            .chanel-card{
                width: 100%;
                height: 48px;
                background: #F0F4F9;
                border-radius: 12px 12px 12px 12px;
                padding: 12px 8px;
                margin-bottom: 4px;
                cursor: pointer;
                user-select: none;
                &.active{
                    background: #CECCFF;
                }
                .contact-img{
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 8px;
                }
                .contact-name{
                    width: 160px;
                    font-family: Roboto-SemiBold;
                    font-weight: 600;
                    font-size: 12px;
                    color: #1C253D;
                    line-height: 14px;
                }
                .contact-job{
                    width: 160px;
                    font-weight: 400;
                    font-size: 11px;
                    color: #1C253D;
                    line-height: 13px;
                    margin-top: 4px;
                }
                .unRead-box{
                    // min-width: 16px;
                    height: 16px;
                    background: #C51A1A;
                    padding: 0 5px;
                    border-radius: 30px 30px 30px 30px;
                    font-family: Roboto-Medium, Roboto;
                    font-weight: 500;
                    font-size: 10px;
                    color: #FFFFFF;
                    font-style: normal;
                    text-align: center;
                    line-height: 16px;
                    margin-left: auto;
                }
            }
        }
        .message-box{
            flex: 1;
            background: #FFFFFF;
            box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
            border-radius: 12px 12px 12px 12px;
            .job-topBox{
                width: 100%;
                background: #4C47DE;
                height: 80px;
                // height: 107px;
                border-radius: 12px 12px 0 0;
                padding: 15px 10px 10px 10px;
                .top-name{
                    font-family: Roboto-SemiBold;
                    font-weight: 600;
                    font-size: 20px;
                    color: #FFFFFF;
                    line-height: 23px;
                    margin-left: 8px;
                }
                .jobChat-icon{
                    width: 24px;
                    height: 24px;
                    background: #F0F4F9;
                    border-radius: 4px 4px 4px 4px;
                    margin-right: 8px;
                }
                .topJOb-tip{
                    font-weight: 400;
                    font-size: 12px;
                    color: #FFFFFF;
                    line-height: 14px;
                }
                .row-lineBox{
                    width: 1px;
                    height: 20px;
                    background: #FFFFFF;
                    margin: 0 20px;
                }
            }
            .message-listBox{
                .message-list{
                    // max-height: calc(100% - 107px);
                    max-height: calc(100vh - 355px);
                    height: calc(100vh - 355px);
                    overflow-y: auto;
                    padding: 30px 20px 20px 20px;
                    .msg-time{
                        font-weight: 400;
                        font-size: 14px;
                        color: #7D7D7D;
                        line-height: 16px;
                        margin-bottom: 20px;
                    }
                    .msg-card{
                        // max-width: 654px;
                        margin-bottom: 40px;
                        .message-text{
                            margin-left: 18px;
                            max-width: 100%;
                            min-height: 60px;
                            background: #F0F4F9;
                            border-radius: 0px 18px 18px 18px;
                            padding: 20px;
                            font-weight: 400;
                            font-size: 16px;
                            color: #000000;
                            line-height: 19px;
                            &.myMessage-text{
                                background: #9490F1;
                                border-radius: 18px 0px 18px 18px;
                                color: #FFFFFF;
                                margin-left: 10px;
                            }
                        }
                        .send-loadingIcon{
                            width: 20px;
                            height: 20px;
                            background: url('../../../assets/img/chat/send-loading.png') no-repeat;
                            background-size: contain;
                        }
                        .send-failIcon{
                            width: 20px;
                            height: 20px;
                            background: url('../../../assets/img/chat/send-fail.png') no-repeat;
                            background-size: contain;
                        }
                    }
                }
                .msg-inp{
                    padding: 5px 20px 30px 20px;
                    .send-icon{
                        width: 48px;
                        min-width: 48px;
                        height: 48px;
                        background: url('../../../assets/img/chat/send-msg.png') no-repeat;
                        background-size: contain;
                        cursor: pointer;
                        margin-left: 18px;
                    }
                }
            }
        }
        .empty-box{
            height: 158px;
            font-weight: 400;
            font-size: 14px;
            color: #7D7D7D;
            line-height: 16px;
            &.message-empty{
                height: calc(100vh - 430px);
            }
        }
    }
}
.message-inp{
    border-radius: 10px;
    height: 48px;
}
.disabled-inp{
    position: relative;
    opacity: 0.5;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: no-drop;
    }
}
.empty-box{
    height: 158px;
    font-weight: 400;
    font-size: 14px;
    color: #7D7D7D;
    line-height: 16px;
    &.message-empty{
        height: calc(100vh - 470px);
    }
}
@keyframes rotate {
    0% {
        transform: rotate(0deg); /* 初始状态 */
    }
    100% {
        transform: rotate(360deg); /* 旋转一圈 */
    }
}
.rotate-element {
    animation: rotate 2s linear infinite; /* 2秒完成一圈，线性动画，且无限循环 */
}