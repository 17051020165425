/*
 * @author        r.shouwei <shouwei.ren@technehq.com>
 * @date          2023-05-08 10:37:09
 * Copyright © Techne All rights reserved
 */
.r-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.r-top {
    display: flex;
    align-items: flex-start;
}
.r-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.r-end{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.marLeft {
    margin-left: auto;
}
.r-input {
    width: 100%;
    height: 44px;
    background: #F0F4F9 !important;
    border-radius: 12px;
    border: 1px solid #F0F4F9;
    font-size: 14px;
    font-family: var(--primary-light-font);
    font-weight: 400;
    color: #1C253D;
    display: flex;
    align-items: center;
    transition: all .3s;
    caret-color: #4C47DE;
    &:hover,&:focus,&:focus-within{
        border: 1px solid #F0F4F9;
        background-color: #F0F4F9;
        box-shadow: none;
    }
    &::placeholder {
        font-size: 14px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: rgba(28, 37, 61, .5) !important;
        line-height: 24px;
    }
    &:focus{
        // color: #4C47DE;
        box-shadow: none !important;
    }
    .ant-input {
        background: #F0F4F9 !important;
        &::placeholder {
            font-size: 14px;
            font-family: var(--primary-light-font);
            font-weight: 400;
            color: rgba(28, 37, 61, .5) !important;
        }
        font-size: 14px !important;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: #1C253D;
        caret-color: #4C47DE;
    }
    &.ant-input-disabled{
        background: #F0F4F9;
        border-color: #F0F4F9;
    }
    
    &.white-bg{
        border: 1px solid #FFFFFF;
        background: #FFFFFF !important;
        &:hover,&:focus,&:focus-within{
            border: 1px solid #FFFFFF;
            background-color: #FFFFFF;
            box-shadow: none;
        }
        .ant-input{
            background: #FFFFFF !important;
        }
    }
    &.ant-input-affix-wrapper .ant-input-prefix {
        margin: 0;
    }
}
.r-inputTextArea{
    height: 200px !important;
    resize: none !important;
    // border: none !important;
    border: 1px solid #F0F4F9;
    white-space: pre-wrap;
    &.r-inputTextAreaNoBg{
        background: #FFFFFF !important;
        border-radius: 10px;
    }
    &.isError{
        border: 1px solid #ff4d4f;
    }
}
.r-input_autoComplate {
    width: 100%;
    height: 44px;
    .ant-select-selector {
        height: 44px !important;
        border-radius: 12px !important;
        border: 1px solid #F0F4F9 !important;
        background: #F0F4F9 !important;
        box-shadow: none !important;
        .ant-select-selection-search {
            height: 40px;
            display: flex;
            align-items: center;
            background-color: #F0F4F9;
            .ant-select-selection-search-input {
                font-size: 14px;
                font-family:Roboto-Regular;
                font-weight: 400;
                color: #1b1b1b;
            }
        }
        .ant-select-selection-placeholder {
            display: flex;
            align-items: center;
        }
    }
}
.textare-countTip{
    font-weight: 400;
    font-size: 12px;
    color: #1C253D;
    opacity: 0.5;
    text-align: left;
    position: absolute;
    right: 0;
}
.textArea-box{
    position: relative;
    width: 100%;
    height: 200px;
    background: #F0F4F9;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #F0F4F9;
    &.isError{
        border: 1px solid #ff4d4f;
    }
    .text-count{
        position: absolute;
        bottom: 6px;
        right: 20px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        font-size: 12px;
        color: rgba(49,49,49,0.5);
        line-height: 15px;
    }
}
.r-input-filter{
    height: 36px;
    background-color: #FFFFFF !important;
    border-radius: 8px;
}
.r-input_serach {
    padding-left: 10px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #DFDEFB;
    background-color: #FFFFFF !important;
    &:hover,&:focus,&:focus-within{
        border: 1px solid #DFDEFB;
        background-color: #FFFFFF;
        box-shadow: none;
    }
    svg {
        font-size: 14px;
        color: #a3a3a3;
    }
    .ant-input-suffix {
        display: flex;
        align-items: center;
        .ant-input-clear-icon {
            display: flex;
            align-items: center;
        }
    }
    .ant-input{
        background-color: #FFFFFF !important;
    }
    .ant-input::placeholder {
        font-size: 14px;
        color: #9BA7C8 !important;
    }
    .search_icon{
        width: 20px;
        height: 20px;
        background: url('../assets/img/Search@2x.png') no-repeat;
        background-size: contain;
        cursor: pointer;
    }
}
.r-select{
    width: 100%;
    height: 44px;
    .ant-select-selector{
        width: 100%;
        border-radius: 12px;
        background: #F0F4F9 !important;
        border: 1px solid #F0F4F9 !important;
        &:focus,&:hover,&:active{
            outline: none !important;
            border: 1px solid #F0F4F9;
            box-shadow: none !important;
            background: #F0F4F9 !important;
        }
    }
    &.white-bg>.ant-select-selector{
        border: 1px solid #FFFFFF;
        background: #FFFFFF !important;
        &:hover,&:focus,&:focus-within,&:active{
            border: 1px solid #FFFFFF;
            background-color: #FFFFFF;
            box-shadow: none !important;
        }
        .ant-input{
            background: #FFFFFF !important;
        }
    }
}
.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #ff4d4f !important;
}
.r-primary_btn,
.r-primaryNoBg_btn {
    position: static;
    z-index: 3;
    height: 36px;
    background: #4C47DE;
    border-radius: 8px;
    opacity: 1;
    border: 1px solid #4C47DE;
    font-size: 14px;
    font-family: var(--primary-light-font);
    letter-spacing: -.5px;
    font-weight: normal;
    cursor: pointer;
    color: #FFFFFF;
    // box-shadow: none;
    &:hover {
        border: 1px solid #4C47DE;
        color: #FFFFFF;
    }
}
.r-primary_btn {
    border: 1px solid #4C47DE;
    color: #FFFFFF;
    background-color: #4C47DE;
    &:disabled {
        border: 1px solid #4C47DE;
        color: #FFFFFF;
        background-color: #4C47DE;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &.disabled {
        border: 1px solid #4C47DE;
        color: #FFFFFF;
        background-color: #4C47DE;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover{
        color: #FFFFFF !important;
        border: 1px solid #4C47DE !important;
        background-color: #4C47DE !important;
    }
}
.r-primaryNoBg_btn{
    width: 100%;
    border: 1px solid #4C47DE;
    background-color: #FFFFFF;
    color: #4C47DE;
    &:disabled {
        border: 1px solid #4C47DE;
        color: #4C47DE;
        background-color: #FFFFFF;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &.disabled {
        border: 1px solid #4C47DE;
        color: #4C47DE;
        background-color: #FFFFFF;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover{
        color: #4C47DE !important;
        border: 1px solid #4C47DE !important;
        background-color: #FFFFFF !important;
    }
}
.r-primaryNoBg7F_btn{
    width: 100%;
    border: 1px solid #7F8AA9;
    background-color: #FFFFFF;
    color: #1C253D;
    &:disabled {
        border: 1px solid #7F8AA9;
        color: #1C253D;
        background-color: #FFFFFF;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &.disabled {
        border: 1px solid #7F8AA9;
        color: #1C253D;
        background-color: #FFFFFF;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover{
        color: #1C253D !important;
        border: 1px solid #7F8AA9 !important;
        background-color: #FFFFFF !important;
    }
}
.r-primary_largeBtn{
    border-radius: 12px;
    height: 48px;
    font-family: var(--primary-medium-font);
    font-size: 16px;
}
.medium_btn{
    font-family: Roboto-Medium;
}
.defaultBlick_btn{
    height: 48px;
}
.loading_icon{
    display: inline-block;
    width: 42px;
    height: 42px;
    background: url('../assets/img/loading.png') no-repeat;
    background-size: contain;
}
.tip-icon{
    width: 24px;
    height: 24px;
    background: url('../assets/img/tip.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.editR-icon{
    width: 24px;
    height: 24px;
    background: url('../assets/img/edit-round.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}

.url-succ-icon{
    min-width: 18px;
    width: 18px;
    height: 18px;
    background: url('../assets/img/url-success.png') no-repeat;
    background-size: contain;
}
.delR-icon{
    min-width: 24px;
    width: 24px;
    height: 24px;
    background: url('../assets/img/Delete-round.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.edit-icon{
    min-width: 24px;
    width: 24px;
    height: 24px;
    background: url('../assets/img/edit.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.del-white-icon{
    min-width: 24px;
    width: 24px;
    height: 24px;
    background: url('../assets/img/Delete-white.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.share-icon{
    min-width: 16px;
    width: 16px;
    height: 16px;
    background: url('../assets/img/share-network.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.remove-white-icon{
    min-width: 12px;
    width: 12px;
    height: 12px;
    background: url('../assets/img/Remove-white.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.r-radioText{
    font-family: var(--primary-light-font);
    font-weight: 400;
    font-size: 16px;
    color: #000;
    line-height: 20px;
    text-align: left;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
}
.check_icon{
    display: block;
    width: 16px;
    height: 16px;
    background: url('../assets/img/Checkbox.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.checked_icon{
    display: block;
    width: 16px;
    height: 16px;
    background: url('../assets/img/Checkboxed.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.more_icon{
    display: block;
    width: 30px;
    height: 30px;
    background: url('../assets/img/opeation.png') no-repeat;
    background-size: contain;
    cursor: pointer;
    transition: all .2s;
    &:hover{
        background: url('../assets/img/opeationed.png') no-repeat;
        background-size: contain;
    }
}
.more_iconSeled{
    display: block;
    width: 30px;
    height: 30px;
    background: url('../assets/img/opeationed.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
@keyframes rotate {
    0% { transform: rotate(0deg); } 
    100% { transform: rotate(360deg); } 
}
.ant-spin .ant-spin-dot{
    animation-name: rotate; 
    animation-duration: .8s;
    animation-iteration-count: infinite; 
    animation-timing-function: linear; 
}
.r-divider{
    color: #9490F1 !important;
    margin: 30px 0 20px 0 !important;
    font-family: var(--primary-light-font);
    font-size: 12px !important;
    .ant-divider-inner-text{
        padding-inline: 30px !important;
    }
    &::after,&::before{
        border-block-start-color: #DFDEFB !important;
    }
}
.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.emptyTetx{
    color: #FFFFFF;
    font-size: 14px;
    padding: 40px 0;
}
.home-nextBtn.disabled{
    opacity: .5;
}
.fullLoading-box{
    .ant-spin{
        position: fixed;
        max-height: initial !important;
        z-index: 1020 !important;
    }
    div>.ant-spin .ant-spin-dot{
        margin: -20px;
    }
    .ant-spin-blur{
        opacity: 1;
        &::after{
            z-index: 100;
            background: #1E222D;
            // backdrop-filter: blur(1px);
            // opacity: .5;
        }
    }
}
.r-modal {
    width: 380px;
    margin: 20px auto;
    .r-modal-close{
        width: 20px;
        height: 20px;
        background-image: url('../assets/img/Remove-black.png');
        background-size: contain;
    }
    .ant-modal-content {
        border-radius: 12px !important;
        padding:40px 32px !important;
        box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.12);
        .reset-text{
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            font-size: 12px;
            color: #4C47DE;
            line-height: 15px;
            text-align: center;
            margin-top: 20px;
            cursor: pointer;
            &.disabled{
                opacity: .5;
                cursor: no-drop;
            }
        }
    }
    .r-primary_btn{
        width: 100%;
    }
    .r-cancel_btn{
        margin-right: 20px;
    }
    .ant-modal-close{
        top: 10px;
        right: 10px;
        background-color: #FFFFFF;
    }
    .modal-top-title{
        text-align: center;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        font-size: 22px;
        color: #1C253D;
        line-height: 26px;
    }
    .ant-modal-header,.ant-modal-footer{
        margin: 0;
    }
}
.tailor-modal{
    .ant-modal-content{
        padding: 24px !important;
    }
}
.r-modal-video{
    .r-modal-close{
        width: 20px;
        height: 20px;
        background-image: url('../assets/img/Remove-white.png');
        background-size: contain;
        top: 0px;
    }
    .ant-modal-close{
        top: -44px;
        right: -5px;
    }
    .ant-modal-content{
        // background-color: rgba(30, 34, 45, 0.8),
        padding: 0;
        background-color: #494c55;
        box-shadow: none;
    }
}
.sub-info-modal{
    .ant-modal-close{
        top: 38px;
        right: 26px;
    }
    .ant-modal-content{
        padding: 40px 35px 30px 35px !important;
    }
    .modal-top-title{
        text-align: left;
    }
}
.mini-tip{
    font-size: 12px;
}
.ant-form-item .ant-form-item-label >label {
    font-family: var(--primary-light-font);
    font-weight: 400;
    font-size: 12px !important;
    color: #1C253D !important;
    line-height: 15px;
    width: 100%;
}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.ant-form-item .ant-form-item-explain-error{
    text-align: left;
    font-family: var(--primary-light-font);
    font-size: 12px;
}
.ant-form-item{
    margin-bottom: 20px;
}
.op5-itemLabel{
    .ant-form-item-label > label{
        opacity: .5;
    }
}
.r-table{
    .ant-table-thead{
        .ant-table-cell{
            font-family: var(--primary-medium-font);
            font-weight: 500;
            font-size: 11px;
            color: #9490F1;
            line-height: 13px;
            border-bottom:1px solid #DFDEFB;
            background-color: #FFFFFF;
            padding: 7px 8px !important; 
            &::before{
                display: none;
            }
        }
    }
    .ant-table-tbody{
        .r-tableRow{
            .ant-table-cell{
                font-family: var(--primary-light-font);
                font-weight: 400;
                font-size: 14px;
                color: #1C253D;
                line-height: 16px;
                padding: 18px 8px !important;
                border-bottom: none;
            }
            &:nth-child(even)>.ant-table-cell{
                background-color: #F0F4F9;
                &:first-child{
                    border-radius: 8px 0 0 8px;
                }
                &:last-child{
                    border-radius: 0 8px 8px 0;
                }
            }
        }
    }
}
.w100{
    width: 100%;
}
.r-datePicker {
    height: 44px;
    background: #F0F4F9 !important;
    border-radius: 12px;
    border: 1px solid #F0F4F9;
    &:hover,&:focus,&:focus-within{
        border: 1px solid #F0F4F9;
        background-color: #F0F4F9;
        box-shadow: none;
    }
    &.white-bg{
        width: 100%;
        background: #FFFFFF !important;
        border: 1px solid #FFFFFF;
        &:hover,&:focus,&:focus-within{
            border: 1px solid #FFFFFF;
            background-color: #FFFFFF;
        }
    }
    &.ant-picker-disabled{
        background-color: rgba(0, 0, 0, 0.04) !important;
        border-color: #d9d9d9 !important;
    }
    .sel_time_icon {
        width: 20px;
        height: 20px;
        background: url('../assets/img/Calendar-inp.png') no-repeat;
        background-size: contain;
    }
    .allow_time_icon {
        width: 13px;
        height: 13px;
        background: url('../assets/img/arrow-down.png') no-repeat;
        background-size: contain;
    }
    &.r-dateMonth>.ant-picker-input:first-child{
        width: 120px !important;
    }
    &.r-w180>.ant-picker-input:first-child{
        width: 180px !important;
    }
    .ant-picker-clear {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 3px;
        svg {
            font-size: 14px;
            color: #a3a3a3;
        }
    }
    .ant-picker-input>input {
        text-align: left;
        font-size: 14px;
        font-family: var(--primary-light-font);
        color: #1C253D;
        &::placeholder {
            font-size: 14px;
            font-family: var(--primary-light-font);
            font-weight: normal;
            color: #9BA7C8 !important;
            opacity: 1;
        }
    }
    .ant-picker-range-separator {
        // display: none;
        opacity: 0;
        display: flex;
        align-items: center;
        position: relative;
        .ant-picker-separator {
            display: none;
        }
        &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 4px;
            height: 2px;
            background: #9BA7C8;
        }
    }
    .ant-picker-active-bar {
        display: none;
        margin-inline-start: 30px !important;
    }
}
.r-downTimePick{
    width: 100%;
    &::before{
        display: none;
    }
}
.r-date_showLine {
    .ant-picker-range-separator {
        opacity: 1;
    }
}

.mb-main{
    .r-modal {
        width: 3.42rem !important;
        .ant-modal-content {
            font-size: 16px;
            border-radius: 12px !important;
            padding:38px .16rem .16rem !important;
            box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.12);
        }
        .r-cancel_btn{
            margin-right: .14rem;
        }
        .ant-modal-close{
            top: 6px;
            right: 6px;
        }
        .r-cancel_btn,.r-primary_btn{
            height: 40px;
            font-size: .14rem;
            font-family: Inter-Medium;
        }
    }
}
.flex1{
    flex: 1;
}
.ant-popover .ant-popover-inner{
    padding: 0;
}
.tableOpeation-box{
    width: 213px;
    &.auth-opBox{
        width: 160px;
    }
    &.job-opBox{
        width: 150px;
    }
    &.remove-opBox{
        width: 120px;
    }
    .tb_item{
        font-family: var(--primary-light-font);
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        line-height: 14px;
        text-align: left;
        padding: 13px 5px 13px 24px;
        cursor: pointer;
        user-select: none;
        &:first-child{
            border-radius:8px 8px 0 0 ;
        }
        &:last-child{
            border-radius: 0 0 8px 8px;
        }
        &:nth-child(1){
            border-radius: 8px;
        }
        &.danger{
            color: #F87171;
        }
        &.success{
            color: #3ECC92;
        }
        &:hover{
            background: #F4F4FE;
        }
    }
}
.r-autoComplate{
    height: 36px;
    border-radius: 12px;
    .ant-select-selector{
        border-radius: 12px;
        border: none !important;
        font-size: 14px;
        color: #1C253D;
        width: 95%;
        &:focus,&:hover,&:focus-within{
            outline: none !important;
            border: none;
            box-shadow: none !important;
        }
    }
    &.r-autoComplate-pool{
        border-radius: 18px;
        .ant-select-selector{
            padding: 0;
            border-radius: 20px;
            .ant-select-selection-search{
                left: 0;
                border-radius: 18px;
                padding: 0 16px !important;
                .ant-select-selection-search-input::placeholder{
                    color: #1C253D;
                }
            }
        }
        .ant-select-selection-placeholder{
            color: #1C253D;
            padding: 0 16px !important;
        }
    }
    &.r-autoComplate-search{
        .ant-select-selector{
            border-radius: 8px;
        }
    }
}
.r-autoComplate-bg{
    .ant-select-selector{
        background: #F0F4F9 !important;
        height: 44px !important;
        border-radius: 12px !important;
    }
}
.skill-tooltipBox{
    .ant-tooltip-content{
        width: 659px;
    }
    .ant-tooltip-inner{
        width: 659px;
        // height: 236px;
        background: #171615;
        border-radius: 15px 15px 15px 15px;
        padding: 20px 15px;
        .tooltip-text{
            font-family: Roboto-Regular;
            font-size: 12px;
        }
        .title-box{
            font-family: Roboto-SemiBold;
            font-weight: 600;
            font-size: 12px;
        }
    }
}