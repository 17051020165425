.candidateFilter-box{
    width: 280px;
    padding-right: 20px;
    border-right: 1px solid #DFDEFB;
    text-align: left;
    .filter-tit{
        font-family: Roboto-SemiBold, Roboto;
        font-weight: 600;
        font-size: 18px;
        color: #1C253D;
        line-height: 21px;
    }
    .filter-tip{
        font-family: Roboto-SemiBold, Roboto;
        font-weight: 600;
        font-size: 14px;
        color: #9490F1;
        line-height: 18px;
        margin: 15px 0 12px 0;
    }
    .filter-marb8{
        margin-bottom: 8px;
    }
    .filter-clear_btn{
        width: 80px;
        margin-right: 12px;
        border: 1px solid #7F8AA9 !important;
        color: #1C253D;
        &:hover{
            border: 1px solid #7F8AA9 !important;
            color: #1C253D !important;
        }
    }
}

.tabs-itemCard-s{
    padding: 0 16px;
    height: 36px;
    background: #EAE9FC;
    border-radius: 8px 8px 8px 8px;
    font-weight: 400;
    font-size: 12px;
    color: #1C253D;
    line-height: 36px;
    user-select: none;
    margin-right: 8px;
    margin-bottom: 8px;
    .tabs-del{
        width: 12px;
        height: 12px;
        background: url('../../../../assets/img/Remove-black.png') no-repeat;
        background-size: contain;
        cursor: pointer;
        margin-left: 8px;
    }
}

// reminder list 
.noticebar-list-container {
    overflow: hidden; // 只显示一个滚动项
    height: 36px; // 与 `swiperHeight` 保持一致
}
.swiper{
    height: 36px !important;
}
.swiper-slide{
    height: 36px !important;
}
