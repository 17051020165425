.savedJOb-mainBox{
    min-height: calc(100vh - 64px);
    .page-contentBox{
        padding:68px 20% 32px 20%;
        .job-cardListBox{
            margin-left: 20px;
            .job-listBox{
                margin-top: 20px;
                .job-cardItem{
                    width: 100%;
                    min-height: 160px;
                    background: #FFFFFF;
                    box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
                    border-radius: 12px 12px 12px 12px;
                    margin-bottom: 12px;
                    padding: 16px;
                    .job-infoLeft{
                        margin-right: 10px;
                        .job-tit{
                            font-family: Roboto-SemiBold;
                            font-weight: 600;
                            font-size: 22px;
                            color: #1C253D;
                            line-height: 26px;
                        }
                        .mini-tipBox-job{
                            margin: 8px 0 16px 0;
                            .jonInfo-icon{
                                width: 24px;
                                min-width: 24px;
                                height: 24px;
                                background: #F0F4F9;
                                border-radius: 4px 4px 4px 4px;
                                margin-right: 8px;
                            }
                            .job-info-tip{
                                font-weight: 400;
                                font-size: 12px;
                                color: #1C253D;
                                line-height: 14px;
                                text-align: left;
                            }
                            .round-spaceBox{
                                font-style: normal;
                                margin: 0 10px;
                            }
                        }
                        .skill-tit{
                            font-family: Roboto-Medium;
                            font-weight: 500;
                            font-size: 11px;
                            color: #9490F1;
                            line-height: 13px;
                        }
                        .skill-tip{
                            font-weight: 400;
                            font-size: 14px;
                            color: #1C253D;
                            line-height: 20px;
                            margin-top: 4px;
                        }
                    }
                    .job-opRight{
                        width: 150px;
                        min-width: 150px;
                        margin-left: auto;
                        .r-primary_btn{
                            width: 100%;
                            &:nth-child(2){
                                margin: 10px 0;
                            }
                        }
                    }
                }
            }
        }
        
        .seclectItem{
            max-width: max-content;
            user-select: none;
            font-weight: 400;
            font-size: 14px;
            color: #1C253D;
            line-height: 20px;
            text-align: left;
            margin-bottom: 8px;
            cursor: initial;
            .selected_icon{
                width: 16px;
                min-width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }
    }
}
// share
.shareModal-box{
    margin-top: 30px;
    .share-cardBox{
        width: 100%;
        background: rgba(155,167,200,0.15);
        border-radius: 10px 10px 10px 10px;
        .topShare-iconBox{
            width: 448px;
            height: 120px;
            background: #1C253D;
            border-radius: 10px 10px 0px 0px;
            .TalenautLogoShare-img{
                height: 40px;
            }
        }
        .share-content{
            padding: 12px 20px 30px 20px;
            font-weight: 400;
            font-size: 13px;
            color: #1C253D;
            line-height: 20px;
            text-align: left;
            .share-tit{
                font-family: Roboto-Bold;
                font-weight: bold;
                font-size: 20px;
                color: #1C253D;
                line-height: 23px;
                text-align: left;
            }
            .share-14{
                margin: 8px 0;
                font-weight: 400;
                font-size: 14px;
                color: #1C253D;
                line-height: 16px;
            }
            .share-link{
                font-weight: 400;
                font-size: 12px;
                color: #4C47DE;
                line-height: 14px;
                text-align: left;
                font-style: normal;
                text-decoration-line: underline;
                margin-top: 12px;
            }
        }
    }
}