.layout-container {
    height: 100%;
    position: relative;
    // height: calc(var(--app-height));
    background-color: #F0F4F9;
    // height: 100vh;
    min-width: 1366px;
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 0 0 0 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 6px;
    }
    .header_nav {
        // left: 0;
        // top: 0;
        // z-index: 99;
        width: 100%;
        height: 64px;
        padding-right: 32px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.03);
        .navTop-icon{
            height: 100%;
            margin-right: 32px;
        }
        .navItem{
            font-weight: 500;
            font-size: 14px;
            font-family: Roboto-Medium;
            padding: 0 16px;
            height: 64px;
            line-height: 64px;
            cursor: pointer;
            user-select: none;
            position: relative;
            &.active{
                color: #4C47DE;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #4C47DE;
                    left: 0;
                    bottom: 0;
                }
            }
            .message-count{
                position: absolute;
                top: 16px;
                min-width: 16px;
                right: 5px;
                height: 16px;
                background: #C51A1A;
                border-radius: 30px 30px 30px 30px;
                text-align: center;
                line-height: 16px;
                font-family: Roboto-Medium;
                font-weight: 500;
                font-size: 10px;
                color: #FFFFFF;
                padding: 0 5px;
            }
        }
        .Notifications-box{
            position: relative;
            margin: 0 24px 0 auto;
            cursor: pointer;
            .Notifications-icon{
                width: 32px;
                height: 32px;
            }
            .notification-num{
                position: absolute;
                min-width: 16px;
                height: 16px;
                background: #C51A1A;
                border-radius: 30px 30px 30px 30px;
                text-align: center;
                line-height: 16px;
                font-family: Roboto-Medium;
                font-weight: 500;
                font-size: 10px;
                color: #FFFFFF;
                padding: 0 5px;
                top: -8px;
                right: -8px;
            }
        }
        .ad-name{
            width: 40px;
            height: 40px;
            // background: #9490F1;
            border-radius: 6px;
            margin-right: 12px;
            user-select: none;
            cursor: pointer;
        }
        .logout-popTip{
            height: 40px;
            cursor: pointer;
            .CaretDown-icon{
                width: 12px;
                height: 12px;
            }
        }
    }
    .content-layout {
        height: calc(100vh - 64px);
        background-color: #F0F4F9;
        overflow-y: auto;
    }
}
.navTop-pop{
    .ant-popover-inner{
        cursor: pointer;
    }
    .top_item{
        width: 128px;
        cursor: pointer;
        user-select: none;
    }
}
