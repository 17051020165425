.userprofile-content-share{
    width: 880px;
    margin: 0 auto;
    padding: 32px 0;
    .pmarb20{
        margin-bottom: 20px;
    }
    .bold-skillTit{
        font-family: Roboto-Bold !important;
        margin-right: 4px;
        min-width: max-content;
    }
    .profile-card{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
        border-radius: 12px 12px 12px 12px;
        padding: 16px;
        &.pmarl20{
            margin-left: 20px;
        }
        .user-img{
            width: 72px;
            height: 72px;
            border-radius: 8px;
        }
        .user-infoName{
            margin-left: 16px;
            font-family: Roboto-SemiBold;
            font-weight: 600;
            font-size: 22px;
            color: #1C253D;
            line-height: 28px;
            text-align: left;
        }
        .fz14{
            font-family: Roboto-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #1C253D;
            line-height: 18px;
            text-align: left;
        }
        .profile-iconInfo{
            padding: 4px 0 0 0;
            .img-fbox{
                min-width: 195px;
                margin-top: 12px;
                font-weight: 400;
                font-size: 14px;
                color: #1C253D;
                line-height: 16px;
                .userImg-box{
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }
        }
        .p-urlTit{
            font-family: Roboto-SemiBold;
            font-weight: 600;
            font-size: 16px;
            color: #1C253D;
            line-height: 20px;
            margin-bottom: 5px;
        }
        .profile-urlBox{
            display: flex;
            align-items: flex-end;
            .p-url-link{
                font-weight: 400;
                font-size: 12px;
                color: #4C47DE;
                line-height: 15px;
                text-decoration: underline;
            }
        }
        .profile-info-pri{
            font-weight: 400;
            font-size: 12px;
            color: #1C253D;
            line-height: 15px;
            text-align: left;
        }
        .user-threeLinkImg{
            width: 32px;
            height: 32px;
            background: #F0F4F9;
            border-radius: 6px 6px 6px 6px;
        }
        .connected-box{
            width: 65px;
            height: 20px;
            background: #59C198;
            border-radius: 12px 12px 12px 12px;
            font-family: Roboto-SemiBold;
            font-weight: 600;
            font-size: 10px;
            color: #FFFFFF;
        }
        .relevantExperienceList{
            flex-wrap: wrap;
            .relevantExperience-item{
                width: 176px;
                height: 102px;
                background: #F0F4F9;
                border-radius: 8px 8px 8px 8px;
                padding: 12px;
                margin-right: 10px;
                margin-bottom: 10px;
                &:nth-child(3n){
                    margin-right: 0;
                }
                &.greenBg{
                    background: #E2FAF1;
                }
                .experie-tit{
                    font-family: Roboto-SemiBold, Roboto;
                    font-weight: 600;
                    font-size: 18px;
                    color: #1C253D;
                    line-height: 25px;
                }
                .jobCheck-icon{
                    width: 16px;
                    height: 16px;
                    margin-left: 4px;
                }
                .experie-job{
                    font-size: 14px;
                    color: #1C253D;
                    font-family: Roboto-Regular;
                    margin: 12px 0 5px 0;
                }
                .experie-jobTime{
                    opacity: 0.5;
                    font-size: 12px;
                    color: #1C253D;
                    font-family: Roboto-Regular;
                }
            }
        }
        .user-fileImg{
            width: 40px;
            height: 40px;
            margin-right: 12px;
        }
        .portList-box{
            flex-wrap: wrap;
            margin-top: 16px;
            .portImg-box{
                min-width: 176;
                width: calc((100% - 20px) / 3);
                height: 130px;
                border-radius: 12px 12px 12px 12px;
                background-color: #ccc;
                margin-right: 10px;
                margin-bottom: 10px;
                background-size: cover !important;
                cursor: pointer;
                &:nth-child(3n){
                    margin-right: 0;
                }
            }
        }
        .viewMore-btn{
            width: 100%;
            user-select: none;
            cursor: pointer;
            height: 40px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #7F8AA9;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            font-size: 14px;
            color: #1C253D;
        }
        .videoCard-box{
            &:nth-child(2){
                margin: 0 10px;
            }
            flex: 1;
            .video-tip{
                font-family: Roboto-Medium;
                font-weight: 500;
                font-size: 13px;
                color: #1C253D;
                line-height: 15px;
                text-align: left;
                margin-bottom: 12px;
            }
            .videoCard-bg{
                width: 100%;
                height: 205px;
                background: rgba(28,37,61,0.7);
                border-radius: 12px 12px 12px 12px;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: 42px;
                    height: 42px;
                    background: url('../../assets/img/candidate-img/play-white.png') no-repeat;
                    background-size: contain;
                    cursor: pointer;
                }
            }
        }
        .roleList-box{
            margin-bottom: 16px;
            &:last-child{
                margin: 0;
            }
        }
        .roleImg{
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }
        .msg-tit{
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            font-size: 14px;
            color: #1C253D;
            line-height: 16px;
            text-align: left;
        }
        .msg-tip{
            margin-top: 16px;
            font-weight: 400;
            font-size: 12px;
            color: #1C253D;
            line-height: 14px;
            text-align: left;
        }
    }
    .candidateInfo-right{
        width: 100%;
        .videoCard-box{
            &:nth-child(2){
                margin: 0 20px;
            }
            flex: 1;
            .video-tip{
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                font-size: 16px;
                color: #1C253D;
                line-height: 19px;
                text-align: left;
                margin-bottom: 12px;
            }
            .videoCard-bg{
                width: 100%;
                height: 326px;
                background: rgba(28,37,61,0.7);
                border-radius: 12px 12px 12px 12px;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: 72px;
                    height: 72px;
                    background: url('../../assets/img/candidate-img/play-white.png') no-repeat;
                    background-size: contain;
                    cursor: pointer;
                }
            }
        }
        .candidateVideo-info{
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
            border-radius: 12px 12px 12px 12px;
            margin: 20px 0;
            padding: 24px 16px;
            .video-infoTit{
                font-size: 18px;
                font-weight: 500;
                color: #4C47DE;
                font-family: Roboto-Medium;
                margin-bottom: 20px;
            }
            .videoInfo-msg{
                flex: 1;
                min-height: 162px;
                background: #F0F4F9;
                border-radius: 12px 12px 12px 12px;
                padding: 16px;
                &:nth-child(2){
                    margin: 0 16px;
                }
            }
            .roleList-box{
                margin-bottom: 20px;
                &:last-child{
                    margin: 0;
                }
            }
            .roleImg{
                width: 32px;
                margin-right: 8px;
            }
            .msg-tit{
                font-family: Roboto-Medium, Roboto;
                font-weight: 500;
                font-size: 14px;
                color: #1C253D;
                line-height: 16px;
                text-align: left;
            }
            .msg-tip{
                margin-top: 16px;
                font-weight: 400;
                font-size: 14px;
                color: #1C253D;
                line-height: 20px;
                text-align: left;
            }
        }
        .relevantExperienceList{
            flex-wrap: wrap;
            .relevantExperience-item{
                width: 272px;
                height: 102px;
                background: #F0F4F9;
                border-radius: 8px 8px 8px 8px;
                padding: 12px;
                margin-right: 16px;
                margin-bottom: 16px;
                &:nth-child(3n){
                    margin-right: 0;
                }
                &.greenBg{
                    background: #E2FAF1;
                }
                .experie-tit{
                    font-family: Roboto-SemiBold, Roboto;
                    font-weight: 600;
                    font-size: 18px;
                    color: #1C253D;
                    line-height: 25px;
                }
                .jobCheck-icon{
                    width: 16px;
                    height: 16px;
                    margin-left: 4px;
                }
                .experie-job{
                    font-size: 14px;
                    color: #1C253D;
                    font-family: Roboto-Regular;
                    margin: 12px 0 5px 0;
                }
                .experie-jobTime{
                    opacity: 0.5;
                    font-size: 12px;
                    color: #1C253D;
                    font-family: Roboto-Regular;
                }
            }
        }
        .portList-box{
            flex-wrap: wrap;
            .portImg-box{
                min-width: 274px;
                width: calc((100% - 40px) / 3);
                height: 203px;
                border-radius: 12px 12px 12px 12px;
                background-color: #ccc;
                margin-right: 12px;
                margin-bottom: 12px;
                background-size: cover !important;
                cursor: pointer;
                &:nth-child(3n){
                    margin-right: 0;
                }
            }
        }
        .viewMore-btn{
            width: 100%;
            user-select: none;
            cursor: pointer;
            height: 40px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #7F8AA9;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            font-size: 14px;
            color: #1C253D;
        }
    }
}