.talent-notificationBox{
    padding: 32px 20%;
    .notification-card{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
        border-radius: 12px 12px 12px 12px;
        padding: 24px;
    }
    .team-topBox{
        h2{
            font-family: Roboto-SemiBold;
            font-weight: 600;
            font-size: 27px;
            color: #1C253D;
            font-style: normal;
        }
        .addTeam-b{
            width: 180px;
            height: 40px;
            font-family:Roboto-Medium;
            font-size: 14px;
        }
        .teamNum-box{
            margin: 5px 0 22px 0;
            font-weight: 400;
            font-size: 14px;
            color: #1C253D;
            line-height: 18px;
            .teamMar30{
                margin-left: 30px;
            }
        }
    }
    .team-table-box{
        margin-top: 26px;
        .team-table-th{
            width: 100%;
            height: 23px;
            border-bottom: 1px solid #DFDEFB;
            .thade-cell{
                padding: 5px 10px;
                font-family: Roboto-Medium;
                font-weight: 500;
                font-size: 11px;
                color: #9490F1;
                text-align: left;
                &.w220{
                    width: 220px;
                }
            }
        }
        .team-table-body{
            padding-top: 12px;
            .team-table-tr{
                width: 100%;
                height: 52px;
                background: #FFFFFF;
                border-radius: 8px 8px 8px 8px;
                margin-bottom: 5px;
                .team-tb-cell{
                    padding: 10px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #1C253D;
                    text-align: left;
                    &.w220{
                        width: 220px;
                    }
                }
            }
        }
    }
    .warp-box{
        flex-wrap: wrap;
    }
    .blue-textNotic{
        color: blue;
        margin: 0 3px;
    }
}