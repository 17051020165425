.clear:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    clear: both;
}
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    padding: 0;
    word-break: break-word;
}
* {
    padding: 0;
    margin: 0;
}
body,
html {
    font-family: var(--primary-light-font);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #F0F4F9 inset !important; 
    -webkit-text-fill-color: #1C253D !important; 
}
input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus {
    box-shadow: 0 0 0 30px #F0F4F9 inset !important; 
    -moz-text-fill-color: #1C253D !important; 
}
input:-ms-autofill,
input:-ms-autofill:hover,
input:-ms-autofill:focus {
    box-shadow: 0 0 0 30px #F0F4F9 inset !important; 
    -ms-text-fill-color: #1C253D !important; 
}
.page_mainBox {
    padding: 40px;
    // min-width: 1190px;
}
.pc-main{
    .infinite-scroll-component__outerdiv{
        width: 100%;
    }
    .noMessage-box{
        width: 100%;
        font-family: var(--primary-light-font);
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        padding: 60px 0 40px 0;
        opacity: .7;
    }
}
.mb-main{
    // background: #000000;
    min-width: 100%;
    // height: calc(var(--app-height));
    .noMessage-box{
        font-family: var(--primary-light-font);
        font-weight: 500;
        font-size: .14rem;
        color: #FFFFFF;
        line-height: .2rem;
        padding:.24rem 0 0 0;
        opacity: .7;
    }
    .centerEmptyText{
        // position: fixed;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        padding-top: 200px;
    }
    .emptyHeight{
        height: 100%;
        &::after{
            height: calc(var(--app-height) - 78px);
        }
    }
}
.baseSize{
    font-size: 16px;
}
.hidden-page{
    transition: all .5s;
    opacity: 0;
}
.show-page{
    opacity: 1;
}
.primary-color{
    color: #4C47DE !important;
}
/* custom scrollbar */
// ::-webkit-scrollbar {
//     width: 20px;
//     background-color: #000000;
//   }
  
//   ::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
  
//   ::-webkit-scrollbar-thumb {
//     background-color: #d6dee1;
//     border-radius: 20px;
//     border: 6px solid transparent;
//     background-clip: content-box;
//   }
  
//   ::-webkit-scrollbar-thumb:hover {
//     background-color: #a8bbbf;
//   }

.form-tip{
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    font-size: 12px;
    color: #1C253D;
    line-height: 15px;
    text-align: left;
    margin-bottom: 10px;
}
.flex1{
    flex: 1;
}
.op5{
    opacity: 0.5;
}
.ellipsis,.oneLineText {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}
.status-box{
    width: max-content;
    padding: 12px;
    height: 30px;
    background: #FFEDD5;
    border-radius: 12px 12px 12px 12px;
    font-family: Roboto-SemiBold;
    font-weight: 600;
    font-size: 12px;
    color: #B57E46;
}
.offer-status{
    background: #D3F8E9;
    color: #59C198;
}
.reject-status{
    background: #FEE3E3;
    color: #F87171;
}
.line-box{
    width: 100%;
    height: 1px;
    background:#DFDEFB;
    margin: 16px 0;
}
  // icon upload
.uploadMain_box{
    .upload_imgBox {
        background: #ffffff;
        position: relative;
        width: 128px;
        height: 128px;
        background: #F0F4F9;
        border-radius: 12px;
        border: 1px dashed rgba(28,37,61,0.5);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        #imgInput,#videoInput,#videoInput-0,#videoInput-1,#videoInput-2 {
            position: absolute;
            z-index: -999;
            opacity: 0;
        }
        .play-iconBtn{
            width: 72px;
            height: 72px;
            background: url('../assets/img/PlayButton.png') no-repeat;
            background-size: contain;
            cursor: pointer;
        }
    }
    .upload-icon{
        display: block;
        margin: 0 auto;
        width: 24px;
        height: 24px;
        background: url('../assets/img/Image-add.png') no-repeat;
        background-size: contain;
        cursor: pointer;
    }
    .upload_tip{
        margin-top: 10px;
        font-weight: 400;
        font-size: 11px;
        color: #1C253D;
        line-height: 14px;
        text-align: center;
    }
    .uploadVideo-icon{
        display: block;
        margin: 0 auto;
        width: 24px;
        height: 24px;
        background: url('../assets/img/File-video.png') no-repeat;
        background-size: contain;
        cursor: pointer;
    }
    .upload_VideoBox{
        width: 100%;
        height: 308px;
        background: #F0F4F9;
        position: relative;
        border-radius: 12px;
        border: 1px dashed rgba(28,37,61,0.5);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .upload_fileBox{
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        .uploadfile-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 32px;
            background: #4C47DE;
            border-radius: 8px;
            cursor: pointer;
            user-select: none;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
        }
        #fileInput {
            position: absolute;
            z-index: -999;
            opacity: 0;
        }
    }
    .uploadfile-info{
        width: 240px;
        height: 36px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #7F8AA9;
        padding: 9px 16px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        color: #1C253D;
        .file-icon{
            width: 18px;
            height: 18px;
            min-height: 18px;
            background: url('../assets/img/FileText.png') no-repeat;
            background-size: contain;
            margin-right: 8px;
        }
        .file-delIcon{
            width: 12px;
            height: 12px;
            min-height: 12px;
            background: url('../assets/img/Remove-black.png') no-repeat;
            background-size: contain;
            margin-left: auto;
            cursor: pointer;
        }
    }
}
.disaledBox,.disabledBox{
    opacity: .5 !important;
    cursor:no-drop !important;
}
.no-message-tabBox{
    width: 100%;
    font-size: 14px;
    padding: 40px 0;
    opacity: .7;
    height: 100px;
}
.break-text{
    word-break: break-word;
    white-space: pre-line;
}
.round-tipIcon{
    width: 16px;
    height: 16px;
    background: url('../assets/img/candidate-img/round-tip.png') no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: 5px;
}
input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}
input::-webkit-credentials-auto-fill-button {
    display: none !important;
    visibility: hidden;
    pointer-events: none;
    position: absolute; 
    right: 0;
}
  
  