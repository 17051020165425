.login-main{
    min-height: 100vh;
    background: #F0F4F9;
    .nav-top{
        width: 100%;
        height: 64px;
        background: #FFFFFF;
        padding: 0 30px;
        .left-logo{
            width: 102px;
        }
    }
    .login-formMin{
        min-height: calc(100vh - 64px);
        padding: 20px 0;
    }
    .center-box{
        text-align: center;
        width: 420px;
        background: #FFFFFF;
        box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
        border-radius: 12px 12px 12px 12px;
        padding: 30px 20px;
    }
    .logo-img{
        width: 187px;
        margin-bottom: 37px;
    }
    .login-formBox{
        background: #FFFFFF;
        overflow: hidden;
        h2{
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            font-size: 22px;
            color: #1C253D;
            line-height: 26px;
            text-align: center;
            margin-bottom: 30px;
        }
        .form-tip{
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            font-size: 12px;
            color: #1C253D;
            line-height: 15px;
            text-align: left;
            margin-bottom: 10px;
        }
        .eye-open{
            width: 18px;
            height: 18px;
            background-image: url('../../assets/img/eye-open.png');
            background-size: contain;
            cursor: pointer;
        }
        .eye-close{
            width: 18px;
            height: 18px;
            background-image: url('../../assets/img/eye-closed.png');
            background-size: contain;
            cursor: pointer;
        }
        .forgetBox{
            text-align: right;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            font-size: 12px;
            color: #4C47DE;
            line-height: 15px;
            margin: 0 0 30px 0;
            &>span{
                cursor: pointer;
            }
        }
        .r-primary_btn{
            width: 100%;
        }
        .other-login{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 110px;
        }
        .linin-icon,.google-icon{
            width: 36px;
            height: 36px;
            cursor: pointer;
        }
        .btm-tip{
            margin-top: 30px;
            font-size: 12px;
            color: #1C253D;
            &>.forgetBox{
                font-size: 12px;
            }
        }
    }
    .success_img{
        width: 148px;
    }
    .succ_tit{
        font-family: Roboto-Medium, Metropolis;
        font-weight: 500;
        font-size: 22px;
        color: #1C253D;
        line-height: 26px;
        text-align: center;
        margin: 0 0 30px 0;
    }
}
.information-main{
    .login-formMin{
        align-items: flex-start;
    }
    .informationCenter-box{
        width: 880px;
        background: #FFFFFF;
        box-shadow: 0px 12px 18px 0px rgba(155,167,200,0.15);
        border-radius: 12px 12px 12px 12px;
        padding: 24px;
        .info-tit{
            font-family: Roboto-SemiBold, Roboto;
            font-weight: 600;
            font-size: 27px;
            color: #1C253D;
            line-height: 34px;
            text-align: left;
        }
        .info-tip{
            font-weight: 400;
            font-size: 16px;
            color: #1C253D;
            line-height: 20px;
            text-align: left;
            margin: 8px 0 32px 0;
        }
        .pri-tit{
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            font-size: 18px;
            color: #4C47DE;
            line-height: 21px;
            text-align: left;
            margin-bottom: 20px;
        }
        .information-formBox{
            .flex1{
                flex: 1;
            }
            .w640{
                width: 640px;
            }
            .video-card{
                width: calc((100% - 40px) / 3);
                &:nth-child(2){
                    margin: 0 20px;
                }
                .video-tip{
                    font-weight: 400;
                    font-size: 11px;
                    color: #1C253D;
                    line-height: 14px;
                    text-align: left;
                    opacity: 0.7;
                    margin: 20px 0 10px 0;
                    height: 42px;
                }
                .remove-link{
                    font-weight: 400;
                    font-size: 12px;
                    color: #F87171;
                    line-height: 14px;
                    text-align: left;
                    text-decoration: underline;
                    user-select: none;
                    cursor: pointer;
                    margin-top: 13px;
                    height: 14px;
                }
            }
        }
        .info-line{
            width: 100%;
            height: 1px;
            background:  #DFDEFB;
            margin: 32px 0 ;
        }
    }
    .skill-typeBox{
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        font-size: 16px;
        color: #1C253D;
        line-height: 20px;
        text-align: left;
        margin: 20px 0 16px 0;
    }
    .experience-cardBox{
        width: 100%;
        // height: 414px;
        background: #F0F4F9;
        border-radius: 10px 10px 10px 10px;
        padding: 16px;
    }
    .cerinfo-card{
        width: 100%;
        min-height: 48px;
        background: #DFDEFB;
        border-radius: 8px 8px 8px 8px;
        padding: 12px;
        font-family: Roboto-Medium;
        font-size: 16px;
        margin-bottom: 10px;
        .exp-tip{
            color: #1C253D;
            opacity: 0.7;
            font-size: 11px;
            margin: 8px 0;
            font-family: Roboto-Regular;
        }
        .exp-size11{
            font-size: 11px;
            font-family: Roboto-Regular;
        }
        .exp-size12{
            font-size: 12px;
            margin-top: 8px;
            font-family: Roboto-Regular;
        }
    }
}
.space20{
    width: 20px;
    height: 100%;
}
.h32-btn{
    height: 32px !important;
}
.back-btn{
    width: 208px;
    height: 48px;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #7F8AA9;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    font-size: 16px;
    color: #1C253D;
    cursor: pointer;
    user-select: none;
    margin-right: 10px;
}
.down-right-icon{
    width: 12px;
    height: 12px;
    background: url('../../assets/img/arrow-down.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.form-addBtn{
    width: max-content;
    padding: 0 12px;
    height: 32px;
    background: #4C47DE !important;
    border-radius: 8px 8px 8px 8px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    user-select: none;
    cursor: pointer;
    border: 1px solid #4C47DE !important;
    &:hover,&:focus{
        background: #4C47DE !important;
        box-shadow: none !important;
        color: #FFFFFF !important;
        border: 1px solid #4C47DE !important;
    }
    &.notActive{
        background: #EAE9FC !important;
        color: #1C253D !important;
        border: 1px solid #EAE9FC !important;
    }
}
.mb-main{
}