.numInputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
        width: 42px;
        height: 52px;
        border-radius: 12px;
        opacity: 1;
        border: 1px solid #F0F4F9;
        text-align: center;
        margin-right: 10px;
        font-size: 20px;
        font-weight: 600;
        font-family: Roboto-SemiBold;
        color: #1C253D;
        letter-spacing: -2.80%;
        background: #F0F4F9;
        caret-color:#1C253D;
        &:focus,&:hover {
            border: 1px solid #F0F4F9;
            // box-shadow: 0 0 0 2px rgba(5, 175, 255, 0.1);
            outline: none;
            background: #F0F4F9;
            box-shadow: none;
        }
        &:last-child{
            margin: 0;
        }
    }
    .ant-otp{
        column-gap: 3px;
    }
    input[type="number"]::-webkit-outer-spin-button,  
    input[type="number"]::-webkit-inner-spin-button {  
        -webkit-appearance: none;  
        margin: 0;  
    }  
    
    /* Firefox */  
    input[type="number"] {  
        -moz-appearance: textfield;  
    }
}
.mb-main{
    .numInputs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
            width: .6rem;
            height: .72rem;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 1px solid rgba(255,255,255,0.3);
            text-align: center;
            font-size: .24rem;
            font-family: Inter-Bold;
            font-weight: bold;
            color: #ffffff;
            background: rgba(255,255,255,0.1);
            caret-color:#FF7957;
            margin: 0;
            &:focus {
                border: 1px solid #FF7957;
                box-shadow: 0 0 0 2px rgba(5, 175, 255, 0.1);
                outline: none;
            }
        }
    }
}